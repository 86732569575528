<template>
  <div class="mb-5 mt-3">
    <v-fade-transition mode="out-in">
      <div :key="1" v-if="!loader">
        <template v-if="$route.name == 'onboarding_new'">
          برای برگزاری جلسه پیش‌مشاوره یک روز و زمان انتخاب کنید
        </template>
        <v-row class="timeAndCalenderArea" justify="center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col cols="12" lg="6" md="6">
            <div class="calendarArea">
              <div class="text-center">
                <v-chip
                    small
                    class="mb-2 smooth-transition"
                    style="display: inline-block"
                    @click="isJalali = false"
                >
                  {{ $t('clientStepper.dataTime.typeCalender[0]') }}
                </v-chip>
                <div style="display: inline-block">
                  <v-switch
                      :dense="$vuetify.breakpoint.smAndDown"
                      class="ma-3 mx-1 "
                      v-model="isJalali"
                      hide-details
                      color="primary"
                      flat
                      hide-spin-buttons
                  >
                  </v-switch>
                </div>
                <v-chip
                    small
                    @click="isJalali = true"
                    class="mb-2 smooth-transition"
                    style="display: inline-block"
                >
                  {{ $t('clientStepper.dataTime.typeCalender[1]') }}
                </v-chip>
              </div>
              <v-fade-transition mode="out-in">
                <SelectDateTimeOnboarding
                    v-if="isJalali"
                    :flow="{
                                             step1:'year',
                                             step2:'month',
                                             step3:'day',
                                           }"
                    :times="times"
                    v-model="date"
                    @selectDay="goToTimes"
                />
                <SelectDateTimeOnboardingGorgian @selectDay="goToTimes" v-else v-model="date" :times="times"/>
              </v-fade-transition>
              <div class="hintArea py-2 mt-7" style="z-index: 5 ;position: relative">
                <div class="font-weight-bold text-right" style="font-size:12px">
                  راهنما:
                </div>
                <div class="d-flex mt-1">
                  <div class="TodayNotation"></div>
                  <div class="HintLabel">علامت امروز</div>
                </div>
                <div class="d-flex mt-2">
                  <div class="HasSessionNotation"></div>
                  <div class="HintLabel"> علامت روز‌های دارای زمان خالی</div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <div class="">
              <div class="mt-5">
                <template v-if="date">
                  زمان های خالی برای روز
                  <span class="font-weight-bold mr-1">
                  {{ selectedDateFormat(date) }}
              </span>
                </template>
              </div>
              <div class="timesAreaScroll mt-3">
                <div class=" timesArea ">
                  <v-fade-transition mode="out-in">
                    <div v-if="renderTime.length != 0">
                      <div class="hintTime my-2">
                        <ul class="mr-5 font-weight-bold">
                          <li>
                            زمان‌ها
                            بر اساس
                            {{ user.tz }}
                          </li>
                          <li>
                            زما‌ن‌ها
                            به صورت 24 ساعته است.
                          </li>
                        </ul>
                      </div>
                      <v-row v-for="(time , i ) in renderTime" :key="i" @click="selectDateTime(time.id)">
                        <v-col cols="6" v-if="selectedTime == time.id">
                          <div class="selectBox text-center" @click="selectTime">
                            انتخاب
                          </div>
                        </v-col>
                        <v-col :cols="selectedTime == time.id ? 6 : 12">
                          <div class="timeBox text-center" :class="selectedTime == time.id && 'activeTime'">
                            {{ time.start | timeFilter }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <div :key="2" v-else-if="date" class="mt-2">
                      برای این روز زمان خالی وجود ندارد.
                    </div>
                  </v-fade-transition>
                </div>
              </div>
              <div class="timezone">
                <p class="font-weight-bold text-right">
                  <v-icon small>mdi-earth</v-icon>
                  منطقه زمانی
                </p>
                <v-autocomplete @change="updateTZ" item-text="text" item-value="value" dense :items="timezonesList"
                                v-model="user.tz"></v-autocomplete>
              </div>

            </div>
          </v-col>
        </v-row>
        <v-window v-model="window" touchless v-else class="white">
          <v-window-item value="calender">
            <div class="calendarArea mt-5">
              <div class="text-center">
                <v-chip
                    small
                    class="mb-2 smooth-transition"
                    style="display: inline-block"
                    @click="isJalali = false"
                >
                  {{ $t('clientStepper.dataTime.typeCalender[0]') }}
                </v-chip>
                <div style="display: inline-block">
                  <v-switch
                      :dense="$vuetify.breakpoint.smAndDown"
                      class="ma-3 mx-1 "
                      v-model="isJalali"
                      hide-details
                      color="primary"
                      flat
                      hide-spin-buttons
                  >
                  </v-switch>
                </div>
                <v-chip
                    small
                    @click="isJalali = true"
                    class="mb-2 smooth-transition"
                    style="display: inline-block"
                >
                  {{ $t('clientStepper.dataTime.typeCalender[1]') }}
                </v-chip>
              </div>
              <v-fade-transition mode="out-in">
                <SelectDateTimeOnboarding
                    v-if="isJalali"
                    :flow="{
                                             step1:'year',
                                             step2:'month',
                                             step3:'day',
                                           }"
                    :times="times"
                    v-model="date"
                    @selectDay="goToTimes"
                />
                <SelectDateTimeOnboardingGorgian @selectDay="goToTimes" v-else v-model="date" :times="times"/>
              </v-fade-transition>
              <div class="hintArea py-2 mt-10" style="z-index: 5 ;position: relative">
                <div class="font-weight-bold text-right" style="font-size:12px">
                  راهنما:
                </div>
                <div class="d-flex">
                  <div class="TodayNotation"></div>
                  <div class="HintLabel">علامت امروز</div>
                </div>
                <div class="d-flex mt-2">
                  <div class="HasSessionNotation"></div>
                  <div class="HintLabel"> علامت روز‌های دارای زمان خالی</div>
                </div>
              </div>
            </div>

            <div class="timezone">
              <p class="font-weight-bold text-right">
                <v-icon small>mdi-earth</v-icon>
                منطقه زمانی
              </p>
              <v-autocomplete @change="updateTZ" dense :items="timezones" v-model="user.tz"></v-autocomplete>
            </div>
          </v-window-item>
          <v-window-item value="times">
            <div class=" fill-height">
              <div class="d-flex text-left mt-3 justify-space-between" @click="window = 'calender'">
                <div>
                  {{ selectedDateFormat(date) }}
                </div>
                <v-btn text color="primary" small class="text-right font-weight-bold">
                  بازگشت به تقویم
                  <v-icon small class="mx-2">mdi-arrow-left</v-icon>
                </v-btn>
              </div>
              <div class=" fill-height" style="">
                <div class="titleTimeSection">
                </div>
                <div class="timesAreaScroll">
                  <div class="mt-8 timesArea">
                    <v-fade-transition mode="out-in">
                      <div v-if="renderTime.length != 0">
                        <div class="hintTime my-2">
                          <ul class="mr-5 font-weight-bold">
                            <li>
                              زمان‌ها
                              بر اساس
                              {{ user.tz }}
                            </li>
                            <li>
                              زما‌ن‌ها
                              به صورت 24 ساعته است.
                            </li>
                          </ul>
                        </div>
                        <v-row v-for="(time , i ) in renderTime" :key="i" @click="selectDateTime(time.id)">
                          <v-col cols="6" v-if="selectedTime == time.id">
                            <div class="selectBox text-center" @click="selectTime">
                              انتخاب
                            </div>
                          </v-col>
                          <v-col :cols="selectedTime == time.id ? 6 : 12">
                            <div class="timeBox text-center" :class="selectedTime == time.id && 'activeTime'">
                              {{ time.start | timeFilter }}
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <div :key="2" v-else-if="date">
                        برای این روز زمان خالی وجود ندارد.
                      </div>
                    </v-fade-transition>

                  </div>
                </div>
              </div>
            </div>
          </v-window-item>
        </v-window>
      </div>
      <div :key="2" v-else>
        <div class="text-center d-flex justify-center align-center flex-column" style="height: 500px">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
/*eslint-disable*/
import SelectDateTimeOnboarding from "@/components/Calendar/SelectDateTimeOnboarding";
import SelectDateTimeOnboardingGorgian from "@/components/Calendar/SelectDateTimeOnboardingGorgian";
import momentJalali from "moment-jalaali";
import moment from 'moment-timezone';
import {mapGetters} from "vuex";

export default {
  components: {SelectDateTimeOnboardingGorgian, SelectDateTimeOnboarding},
  props: {
    submiting: Boolean,
    user: Object,
    propsTimes: {
      default: null,
    },
    session_id: {
      default: null,
    }
  },
  computed: {
    ...mapGetters({
      'AppUrl': 'getAppUrl',
    })
  },

  data() {
    return {
      loader: false,
      date: null,
      isJalali: true,
      window: "calender",
      sessionID: null,
      times: {
        // "2023-06-20": [
        //   [
        //     {
        //       "id": 2,
        //       "by_user_id": null,
        //       "start": "2023-06-17 12:00",
        //       "end": "2023-06-17 16:00"
        //     },
        //   ],
        // ],
      },
      renderTime: [],
      selectedTime: null,
      doctorInfo: null,
      timezonesList: null,
    }
  },
  methods: {
    sendSessionRequest() {
      this.$emitEvent('setLoading', true)
      this.requesting('session', 'sendSessionRequest', null, {
        cycle_id: this.user.cycles[0].id,
        session_count: 1
      }).then((resp) => {


        console.log(resp)
        this.times = resp.data.doctorTimes

        this.sessionID = resp.data.sessions[0]
        this.doctorInfo = resp.data.doctor
        this.$store.dispatch('setDoctor', this.doctorInfo)

        if (this.times.length == 0) {
          this.$emit('noTimeHandler')
        }
      }).catch(() => {

        this.$emit('closeHandler')

      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    selectedDateFormat(date) {
      return momentJalali(date).format('YYYY/MM/DD')
      // if (this.isJalali) {
      //   return moment(date).format('jYYYY/jMM/jDD')
      // }
      // return moment(date).format('YYYY/MM/DD')
    },
    selectDateTime(timeID) {
      this.selectedTime = timeID
    },
    prepareRenderingTime() {
      let date = this.date
      this.renderTime = []
      setTimeout(() => {
        if (this.isJalali) {
          date = this.jalaliToGregorian(this.date, 'YYYY-MM-DD')
        } else {
          date = momentJalali(this.date).format('YYYY-MM-DD')
        }
        // this.times.forEach(item => {
        //   if (item.date == date) {
        //     this.renderTime = item.periods
        //   }
        // })
        if (this.times[date]) {
          this.renderTime = this.times[date][0]
        }
      }, 300)
    },
    goToTimes() {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.window = 'times'
      }
      this.prepareRenderingTime()
    },
    updateTZ() {
      let data = {
        timezone: this.user.tz,
      };
      this.$emitEvent('setLoading', true)
      this.requesting('auth', 'editProfile', '', data).then(() => {
        this.$emitEvent('notify', {
          title: this.$t('userOnboarding.notify.tz'),
          color: 'green',
          id: 'notify_success'
        })
        this.renderTime = null
        this.date = null
        // this.getTimesForPreSession()
        this.sendPreSessionRequestAndGetTime()
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    sendPreSessionRequestAndGetTime() {
      this.times = []
      this.renderTime = []
      this.requesting('session', 'sendPreSessionRequestAndGetTime').then((resp) => {
        if (resp.data.times.length != 0) {
          this.times = resp.data.times
          // console.log(this.times, '2121212')
          // this.sessionID = resp.data.sessions
        } else {
          this.$emitEvent('notify', {title: 'جلسه شما ثبت شد. اما دکتر در این لحطه زمان خالی ندارد.', color: 'green'})
          window.location.href = `${this.AppUrl}/fa/panel/dashboard?from=onboarding`
          // this.$emit('nextHandler')
        }
      }).catch((er) => {
        if (er.response.status == 400) {
          this.$router.push({name: "dashboard"})
        }
        // this.$emit('nextHandler')
      })
    },
    getTimesForPreSession() {
      this.times = []
      this.renderTime = []
      this.requesting('session', 'getTimesForPreSession', {sessionID: this.sessionID}).then((resp) => {
        this.times = resp.data.doctorTimes
      })
    },
    storeSelectedTimeInStore(timeId) {
      let date;
      if (this.isJalali) {
        date = moment(this.jalaliToGregorian(this.date)).format("YYYY-MM-DD");
      } else {
        date = moment(this.date).format("YYYY-MM-DD");
      }
      let target = this.times[date]
      if (target) {
        let selectedTime = target[0].find(item => item.id == timeId)
        this.$store.dispatch('selectedTime', {
          date,
          time: selectedTime,
        })
      }

    },
    selectTime() {
      if (this.$route.name == "sessions-request") {
        this.$emitEvent('setLoading', true)
        this.loader = true
        this.storeSelectedTimeInStore(this.selectedTime)
        this.requesting('session', 'setTimeForSession', {userSession: this.sessionID}, {
          time: this.selectedTime
        }).then((resp) => {
          this.$store.dispatch('setSkypePreSession', resp.data.skype_link)
          // setTimeout(() => {
          this.loader = false
          this.$emit('nextHandler')

          // }, 300)
        }).catch(er => {
          this.$emitEvent('notify', {title: 'در فرایند انتخاب زمان جلسه مشکلی ایجاد شده است.', color: 'red'})
          this.$emitEvent('setLoading', false)
        }).finally(() => {
          this.loader = false
          // this.$emitEvent('setLoading', false)
        })
      } else {

        this.$emitEvent('setLoading', true)
        this.loader = true
        this.storeSelectedTimeInStore(this.selectedTime)
        this.requesting('session', 'selectTimeForPreSession', {}, {
          time_id: this.selectedTime
        }).then((resp) => {
          this.$store.dispatch('setSkypePreSession', resp.data.skype_link)
          // setTimeout(() => {
          this.loader = false
          this.$emit('nextHandler')

          // }, 300)
        }).catch(er => {
          this.$emitEvent('notify', {title: 'در فرایند انتخاب زمان جلسه مشکلی ایجاد شده است.', color: 'red'})
          this.$emitEvent('setLoading', false)
        }).finally(() => {
          this.loader = false
          // this.$emitEvent('setLoading', false)
        })


      }


    },
    prepareInitialData() {
      if (this.$route.name == "sessions-request") {
        this.sendSessionRequest()
      } else {
        this.sendPreSessionRequestAndGetTime()
      }

    }
  },
  mounted() {

    console.log('load here')
    let times = JSON.parse(JSON.stringify(this.timezones));
    this.timezonesList = times.map((item) => {
      item.text = item.text + '  ' + moment.tz(item.text).format('Z');
      return item;
    });

    if (this.session_id) {
      this.sessionID = this.session_id
    }

    if (!this.propsTimes) {
      this.prepareInitialData()
    } else {
      this.times = this.propsTimes
    }
  },
  watch: {
    date(val) {
      // this.prepareRenderingTime();
    },
    isJalali(val) {
      this.renderTime = []
      this.date = null
      // if (val == false && this.date) {
      //   this.date = this.jalaliToGregorian(this.date)
      // } else {
      //   this.date = null
      // }
    },
    submiting() {
      console.log('here')
      this.$emit('nextHandler', 1)
    }
  },
  filters: {
    timeFilter(date) {
      return momentJalali(date).format("HH:mm")
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .timesArea {
    width: 100% !important;
  }

  .timeBox {
    font-size: 12px !important;
  }

  .calendarArea {
    width: 100% !important;
  }

  .textSideCalender {
    margin: 0px !important;
    text-align: center !important;
  }
}

.hintTime {
  font-size: 11px;
}

.timezone {
  margin-top: 20px;
  z-index: 2;
  position: relative;
}

.timezone p {
  font-size: 12px;
}

.textSideCalender {
  margin-top: 50px;
  font-size: 12px;
}

.timesAreaScroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: 320px;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dddddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.timeAndCalenderArea {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0px auto;
}

.calendarArea {
  width: 350px;
  margin: 0px auto;
}

.titleTimeSection {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  /* identical to box height, or 133% */
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;

  /* label parimary */
  color: #434343;
}

.timeBox {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #4597DF;
  border-radius: 8px;
  height: 30px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-around;
}

.selectBox {
  cursor: pointer;
  background: rgb(60, 190, 139);
  /*border: 1px solid #4597DF;*/
  border-radius: 8px;
  color: white;
  height: 30px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-around;
  transition: all 0.3s;
}

.timesArea {
  width: 90%;
  margin: 0px auto;
}

.activeTime {
  background: #4597DF;
  color: white;
}
</style>